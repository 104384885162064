<template>
    <div>
        <div class="vx-row mt-3">
            <div class="vx-col w-full mb-base">
                <Table
                    :draw="draw">
                </Table>
            </div>
        </div>
    </div>
</template>
<script>

import Table from "./component/tableExport.vue";
export default {
    components: {
        Table
    },
    data() {
        return {
            
        }
    },
    methods: {
        handleFilter() {
            this.draw++;
        },
    },
    watch: {

    }
}
</script>
